@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font-family: 'Poppins';
  font-style: normal;
  }

  /* @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  .animate-marquee {
    animation: marquee 20s linear infinite;
  } */
  
  @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  .marquee-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .marquee-content {
    display: flex;
    white-space: nowrap;
    animation: marquee 15s linear infinite;
  }
  
  .marquee-content img {
    display: block;
    /* margin-right: 40px; Adjust spacing between images as needed */
  }
  
  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }